import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import ImageOpener from '../components/imageOpener';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { respondTo } from '../globals/globalStyles';

const Container = styled.div`
  height: 100%;

  .inner {
    padding: 10vh 10vw;

    ${respondTo.sm`
      min-width: 550px;
      max-width: 55vw;
      padding-left: 10vw;
      padding-top: 20vh;
    `}
  }
`;

const Paragraph = styled.p`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.75s;
  transition-delay: ${(props) => props.index * 1}s;
`;

const FloatParagraph = styled.p`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: ${(props) => (props.visible ? 'translateY(0)' : 'translateY(20px)')};
  transition: opacity 0.75s, transform 1s ease;
  transition-delay: ${(props) => props.index * 1}s;
`;

const Contact = styled.a`
  font-style: italic;
`;

const IndexPage = ({ data }) => {
  const pageData = data.pagesYaml;
  const [isLoaded, setIsLoaded] = useState(false);
  const timeout = useRef(0);
  const [shouldFloat, setShouldFloat] = useState(false);

  if (!isLoaded && !timeout.current) {
    timeout.current = setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  }

  useEffect(() => {
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setShouldFloat(!(isSafari && !iOS));
  }, []);


  const paragraphs = pageData.paragraphs.map((paragraph, idx) => {
    const paragraphContent = paragraph.content.map((item, index) => {
      if (!item.action) {
        return <span key={index}>{item.text}</span>;
      }

      switch (item.action) {
        case 'link':
          return <OutboundLink key={item.href} target="_blank" href={item.href} rel="noopener noreferrer nofollow">{item.text}</OutboundLink>;
        case 'email':
          return <Contact key={item.href} href={item.href}>{item.text}</Contact>;
        case 'image':
          return <ImageOpener key={index} text={item.text} />;
        default:
          return <span key={index}>{item.text}</span>;
      }
    });

    if (shouldFloat) {
      return (
        <FloatParagraph visible={isLoaded} index={idx} key={paragraph.id}>
          {paragraphContent}
        </FloatParagraph>
      );
    }

    return (
      <Paragraph visible={isLoaded} index={idx} key={paragraph.id}>
        {paragraphContent}
      </Paragraph>
    );
  });

  return (
    <Layout>
      <SEO title="Home" />
      <Container>
        <div className="inner">
          {paragraphs}
        </div>
      </Container>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    pagesYaml: PropTypes.shape({
      paragraphs: PropTypes.array,
      contact: PropTypes.string,
    }),
  }).isRequired,
};

export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    pagesYaml(slug: { eq: "home" }) {
      id
      slug
      title
      paragraphs {
        id
        content {
          text
          action
          href
        }
      }
    }
  }
`;
