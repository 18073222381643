import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styled from 'styled-components';

const Toggle = styled.span`
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s;

  &:hover {
    color: #eee;
  }
`;

const ImgWrapper = styled.div`
  margin-top: 1.5em;
`;

const ImageOpener = ({ text }) => {
  const [toggle, setToggle] = useState(false);

  const data = useStaticQuery(graphql`
    query MyQuery {
      file(relativePath: { eq: "beeb.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Toggle
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        {text}
      </Toggle>
      {toggle && (
        <ImgWrapper>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="My cat Nicolas"
          />
        </ImgWrapper>
      )}
    </>
  );
};

ImageOpener.propTypes = {
  text: PropTypes.string,
};

ImageOpener.defaultProps = {
  text: '',
};

export default ImageOpener;
